import Swiper from 'swiper/dist/js/swiper';
window.Swiper = Swiper;

let done, leftContent, rightContent;
let left = document.querySelector('.compatibilites-left');
let right = document.querySelector('.compatibilites-right');
let logoSlider = null;
let formLocaliser = document.querySelector('.formLocaliser');
let services = document.querySelector('.services');
let servicesSlider = null;
let messages = document.querySelector('.messages');
let messagesSlider = null;

let calculateLogos = function (event) {
    if (window.innerWidth <= 1170 && done == 0) {
        done = 1;
        document.querySelector('.compatibilites-left').innerHTML = leftContent + rightContent;

        logoSlider = new Swiper('.compatibilites-container', {
            slideClass: 'imgSlide',
            spaceBetween: 0,
            autoplay: true,
            speed: 500,
            delay: 2000,
            width: 150
        });
    } else if (window.innerWidth > 1170 && done == 1) {
        document.querySelector('.compatibilites-left').innerHTML = leftContent;
        done = 0;
        if (logoSlider) {
            logoSlider.destroy();
        }
    }
};

if (left) {
    rightContent = right.innerHTML;
    leftContent = left.innerHTML;
    done = 0;
    calculateLogos();
    window.onresize = calculateLogos;
}

let changeText = function () {
    if (window.innerWidth <= 600) {
        document.querySelector('.verify').setAttribute('value', 'OK');
    } else {
        document.querySelector('.verify').setAttribute('value', 'Localiser');
    }
};

if (formLocaliser) {
    changeText();
    window.onresize = changeText;
}

if (services && window.innerWidth <= 1030) {
    servicesSlider = new Swiper('.services-container', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        slideClass: 'service',
        spaceBetween: 20,
        centeredSlides: true,
        autoplay: true,
        speed: 500,
        delay: 2000,
        pager: false,
        controls: false,
    });
}

if (messages && window.innerWidth <= 1030) {
    messagesSlider = new Swiper('.messages-slider', {
        freeMode: true,
        slideClass: 'message',
        spaceBetween: 20,
        centeredSlides: true,
        autoplay: true,
        speed: 500,
        delay: 2000,
        pager: false,
        controls: false,
        width: 190
    });
}